<template>
  <div id="container">

    <section id="register">
      <div class="wrap">

        <div class="cts_box">
          <h2 class="cts_ttl"><span>お支払い情報の変更を受け付けました</span></h2>
          <div class="thanks_lead">
            <p>
            </p>
          </div>
        </div>

        <div class="cts_box">
          <div class="notice no_pay">
            <h3>しばらく経ってもお支払い情報が更新されない場合は</h3>
            <p>
              お支払い情報の更新に時間がかかっている事が考えられます。<br>
              申し訳ありませんが、しばらく時間をおいても更新されない場合には<br class="forsp">改めてお支払い情報を更新してください。
            </p>
          </div>
        </div>

        <div class="cts_box">
          <p class="alnC sp_alnL mb20">
          </p>
          <div class="button_wrap">
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="submit basic arrow">マイページへ</router-link>
          </div>
        </div>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
    }
  }
}
</script>
